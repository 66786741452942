html {
  background: #ee5050;
  font-size: 125%;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .index {
  font-size: 1em;
  line-height: 1.5;
}


body:before, body:after {
  background: #EE5050;
  content: '';
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 99999;
}

body:before {
  top: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

*, *:before, *:after {
  box-sizing: border-box;
}

body:after {
  bottom: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

footer {
  background: #EE5050;
  padding-top: 16px;
}

.h--whisper {
  color: #CF3A3A;
  margin: 0 auto;
  text-align: center;
  font-size: 10.5vw;
}

.home-whisper--1 span {
  display: block;
}

.home-whisper--2 {
  font-size: 5.5vw;
}

.home-whisper--2 span{
  font-size: 5.5vw;
  display: block;
}

.home-whisper--3 {
  font-size: 3.5vw;
}

.home-whisper--3 span{
  font-size: 4vw;
  display: block;
  letter-spacing: normal;
}

.SlideIn-appear {
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);;
  transition: all 500ms ease-in-out;
}
.SlideIn-enter {
    opacity: 0;
}
.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all 0.5s linear 0.4s;
}
.SlideIn-leave {
    opacity: 1.0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transition: all 0.5s linear;
}

.SlideOut-appear {

  opacity: 0;
}

.SlideOut-appear.SlideOut-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 500ms ease-in-out;
}
.SlideOut-enter {
    opacity: 0;

}
.SlideOut-enter.SlideOut-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.2s linear 0.4s;
}
.SlideOut-leave {
    opacity: 1.0;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
}
.SlideOut-leave.SlideOut-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;

    transition: all 0.2s linear;
}
@charset "utf-8";

/* 防止用户自定义背景颜色对网页的影响，添加让用户可以自定义字体 */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizelegibility;
}

/* 如果你的项目仅支持 IE9+ | Chrome | Firefox 等，推荐在 <html> 中添加 .borderbox 这个 class */
html.borderbox *, html.borderbox *:before, html.borderbox *:after {
  box-sizing: border-box;
}

/* 要注意表单元素并不继承父级 font 的问题 */
body, button, input, select, textarea {
  font: 300 1em/1.8 PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* 去掉各Table cell 的边距并让其边重合 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 去除默认边框 */
fieldset, img {
  border: 0;
}

/* 块/段落引用 */
blockquote {
  position: relative;
  color: #999;
  font-weight: 400;
  border-left: 1px solid #1abc9c;
  padding-left: 1em;
  margin: 1em 3em 1em 2em;
}

@media only screen and ( max-width: 640px ) {
  blockquote {
    margin: 1em 0;
  }
}

/* Firefox 以外，元素没有下划线，需添加 */
acronym, abbr {
  border-bottom: 1px dotted;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-decoration: none;
}

/* 添加鼠标问号，进一步确保应用的语义是正确的（要知道，交互他们也有洁癖，如果你不去掉，那得多花点口舌） */
abbr {
  cursor: help;
}

/* 一致的 del 样式 */
del {
  text-decoration: line-through;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: 400;
}

/* 去掉列表前的标识, li 会继承，大部分网站通常用列表来很多内容，所以应该当去 */
ul, ol {
  list-style: none;
}

/* 对齐是排版最重要的因素, 别让什么都居中 */
caption, th {
  text-align: left;
}

q:before, q:after {
  content: '';
}

/* 统一上标和下标 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

:root sub, :root sup {
  vertical-align: baseline; /* for ie9 and other modern browsers */
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.typo a {
  border-bottom: 1px solid #1abc9c;
}

.typo a:hover {
  border-bottom-color: #555;
  color: #555;
  text-decoration: none;
}

/* 默认不显示下划线，保持页面简洁 */
ins, a {
  text-decoration: none;
}

/* 专名号：虽然 u 已经重回 html5 Draft，但在所有浏览器中都是可以使用的，
 * 要做到更好，向后兼容的话，添加 class="typo-u" 来显示专名号
 * 关于 <u> 标签：http://www.whatwg.org/specs/web-apps/current-work/multipage/text-level-semantics.html#the-u-element
 * 被放弃的是 4，之前一直搞错 http://www.w3.org/TR/html401/appendix/changes.html#idx-deprecated
 * 一篇关于 <u> 标签的很好文章：http://html5doctor.com/u-element/
 */
u, .typo-u {
  text-decoration: underline;
}

/* 标记，类似于手写的荧光笔的作用 */
mark {
  background: #fffdd1;
  border-bottom: 1px solid #ffedce;
  padding: 2px;
  margin: 0 5px;
}

/* 代码片断 */
pre, code, pre tt {
  font-family: Courier, 'Courier New', monospace;
}

pre {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 1em 1.5em;
  display: block;
  -webkit-overflow-scrolling: touch;
}

/* 一致化 horizontal rule */
hr {
  border: none;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 0.8em;
  height: 10px;
}

/* 底部印刷体、版本等标记 */
small, .typo-small,
  /* 图片说明 */
figcaption {
  font-size: 0.9em;
  color: #888;
}

strong, b {
  font-weight: bold;
}

/* 可拖动文件添加拖动手势 */
[draggable] {
  cursor: move;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* 强制文本换行 */
.textwrap, .textwrap td, .textwrap th {
  word-wrap: break-word;
  word-break: break-all;
}

.textwrap-table {
  table-layout: fixed;
}

/* 提供 serif 版本的字体设置: iOS 下中文自动 fallback 到 sans-serif */
.serif {
  font-family: Palatino, Optima, Georgia, serif;
}

/* 保证块/段落之间的空白隔行 */
.typo p, .typo pre, .typo ul, .typo ol, .typo dl, .typo form, .typo hr, .typo table,
.typo-p, .typo-pre, .typo-ul, .typo-ol, .typo-dl, .typo-form, .typo-hr, .typo-table, blockquote {
  margin-bottom: 1.2em
}

h1, h2, h3, h4, h5, h6 {
  font-family: PingFang SC, Verdana, Helvetica Neue, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  font-weight: 100;
  line-height: 1.35;
}

/* 标题应该更贴紧内容，并与其他块区分，margin 值要相应做优化 */
.typo h1, .typo h2, .typo h3, .typo h4, .typo h5, .typo h6,
.typo-h1, .typo-h2, .typo-h3, .typo-h4, .typo-h5, .typo-h6 {
  margin-top: 1.2em;
  margin-bottom: 0.6em;
  line-height: 1.35;
}

.typo h1, .typo-h1 {
  font-size: 2em;
}

.typo h2, .typo-h2 {
  font-size: 1.8em;
}

.typo h3, .typo-h3 {
  font-size: 1.6em;
}

.typo h4, .typo-h4 {
  font-size: 1.4em;
}

.typo h5, .typo h6, .typo-h5, .typo-h6 {
  font-size: 1.2em;
}

/* 在文章中，应该还原 ul 和 ol 的样式 */
.typo ul, .typo-ul {
  margin-left: 1.3em;
  list-style: disc;
}

.typo ol, .typo-ol {
  list-style: decimal;
  margin-left: 1.9em;
}

.typo li ul, .typo li ol, .typo-ul ul, .typo-ul ol, .typo-ol ul, .typo-ol ol {
  margin-bottom: 0.8em;
  margin-left: 2em;
}

.typo li ul, .typo-ul ul, .typo-ol ul {
  list-style: circle;
}

/* 同 ul/ol，在文章中应用 table 基本格式 */
.typo table th, .typo table td, .typo-table th, .typo-table td, .typo table caption {
  border: 1px solid #ddd;
  padding: 0.5em 1em;
  color: #666;
}

.typo table th, .typo-table th {
  background: #fbfbfb;
}

.typo table thead th, .typo-table thead th {
  background: #f1f1f1;
}

.typo table caption {
  border-bottom: none;
}

/* 去除 webkit 中 input 和 textarea 的默认样式  */
.typo-input, .typo-textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

.typo-em, .typo em, legend, caption {
  color: #000;
  font-weight: inherit;
}

/* 着重号，只能在少量（少于100个字符）且全是全角字符的情况下使用 */
.typo-em {
  position: relative;
}

.typo-em:after {
  position: absolute;
  top: 0.65em;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  content: "\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB\30FB";
}

/* Responsive images */
.typo img {
  max-width: 100%;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Thu Mar 14 2019*/
@font-face {
    font-family: 'Glyphter';
    src: url(/static/media/Glyphter.af6794a7.eot);
    src: url(/static/media/Glyphter.af6794a7.eot?#iefix) format('embedded-opentype'),
         url(/static/media/Glyphter.5eccc49d.woff) format('woff'),
         url(/static/media/Glyphter.cd9020b8.ttf) format('truetype'),
         url(/static/media/Glyphter.d24c5f7c.svg#Glyphter) format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-cssa-logo:before{content:'A';}
.App {
  /* text-align: center; */
}

::-moz-selection {
  background: #1b1b1b;
  text-shadow: none;
  color: #fff;
}

::selection {
  background: #1b1b1b;
  text-shadow: none;
  color: #fff;
}

body, button, input, select, textarea {
  color: #fff;
  /* fill: #fff; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  fill: inherit;
  font-weight: 600;
  transition: fill .25s cubic-bezier(.625, .125, .305, .875), color .25s cubic-bezier(.625, .125, .305, .875);
  text-decoration: none;
}

/* a:focus {
  outline: thin dotted;
} */

p, pre {
  margin: 1em 0;
}

.masthead .group {
  position: relative;
  padding: 0.5rem;
  width: 100%;
}

.masthead__bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: #ee5050;
  transition: background 500ms ease-in-out, -webkit-transform .25s;
  transition: background 500ms ease-in-out, transform .25s;
  transition: background 500ms ease-in-out, transform .25s, -webkit-transform .25s;
}

.department_head {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  padding: 16px 32px;
  text-align: right;
}

[data-history-state=departments] .masthead__bg, nav__link {
  color: #EE5050;
  background: #f4f4f7;
}

[data-history-state=departments] .nav__link {
  color: #EE5050;
}

[data-history-state=departments] .cssa-logo {
  color: #EE5050;
}

.group {
  margin: 0 auto;
  max-width: 61.5rem;
  width: calc(100% - 3rem);
}

.masthead {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  padding: 16px 32px;
}

[data-history-state^=d\/] {
  -webkit-transform: translate(0%, -100%);
  transform: translate(0%, -100%);
}

.cssa-logo {
  font-size: 60px;
  color: #fff;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: 1;
  left: 0;
  position: absolute;
  bottom: -30px;
}

.cssa-logo-whisper {
  color: #CF3A3A;
  margin: 0 auto;
  text-align: center;
  font-size: 260px;
}

.department-index {
  background: #f4f4f7;
}

.nav {
  display: block;
  margin-left: auto;
  text-align: right;
}

.nav__list {
  display: inline-block;
  margin: auto;
  padding: 0;
  vertical-align: middle;
  width: 100%;
}

.nav__item {
  display: inline-block;
  margin: 0;
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
}

.nav__link {
  color: #fff;
  display: block;
  line-height: 1.6rem;
  font-weight: 700;
  outline: none !important;
  padding: 0.4rem 0.4rem;
  position: relative;
  transition: color 0.25s cubic-bezier(0.625, 0.125, 0.305, 0.875), opacity 0.25s cubic-bezier(0.625, 0.125, 0.305, 0.875);
}

.h, .h1 {
  -ms-font-feature-settings: "liga" 1, "kern" 1;
  -o-font-feature-settings: "liga" 1, "kern" 1;
  -webkit-font-feature-settings: "liga" 1, "kern" 1;
  font-feature-settings: "liga" 1, "kern" 1;
  display: block;
  font-weight: 600;
  letter-spacing: -.05em;
  line-height: 1.2;
  margin: 2em auto 1em;
  max-width: 61.5rem;
}

.h1 {
  margin: 3.5rem auto;
  text-align: center;
}

.s72, .h1 {
  font-size: 1.8rem;
}

.section-department .h1, .section-department-inverse .h1 {
  font-size: 32px;
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@media only screen and (max-width: 400px) {
  .h, .h1 {
    font-size: 27px;
  }
  .section-department .h1, .section-department-inverse .h1 {
    font-size: 27px;
  }
}

.department_head {
  -webkit-animation: buttonDropDown 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: buttonDropDown 0.5s;
}

.links--accent a:hover, .link--accent:hover, .links--accent a:focus, .link--accent:focus {
  fill: #a02727;
  color: #a02727;
}

.p {
  -ms-font-feature-settings: "liga" 1, "kern" 1;
  -o-font-feature-settings: "liga" 1, "kern" 1;
  -webkit-font-feature-settings: "liga" 1, "kern" 1;
  font-feature-settings: "liga" 1, "kern" 1;
  display: block;
  line-height: 1.5;
  margin: 1em auto .5em;
  max-width: 33.6rem;
}

.department-p-1 {
  text-indent: 2em;
  font-size: 13pt;
  line-height: 1.5;
  font-weight: normal;
}

.department-p-1 span.hignlight {
  color: #a02727;
}

.department-p-1-no-indent {
  text-indent: 0;
  font-size: 13pt;
  line-height: 1.5;
  font-weight: normal;
}

.section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 2.5rem 0 5rem;
  position: relative;
  width: 100%;
}

.section--first {
  padding-top: 5rem;
}

.section-department {
  padding-top: 0;
  padding-bottom: 32px;
}

.section-department-q-and-a {
  padding-bottom: 0px;
}

.section-department-inverse {
  color: #1b1b1b;
  background: #f4f4f7;
}

.cv-notice {
  font-size: 4vw!important;
  padding: 32px;
  text-align: left;
  color: #fff;
  line-height: 1.5;
  margin-top: 0;
}

.contact-footer__subtitle {
  color: #a02727;
  margin-top: 2rem;
}

.footer-comm {
  color: #a02727;
  margin-bottom: 1rem;
}

.allcaps {
  letter-spacing: .015em;
  text-transform: uppercase;
}

.wm {
  font-weight: 500;
}

.wb {
  font-weight: 600;
}

.mt0 {
  margin-top: 0;
}

.s13 {
  font-size: .85rem;
}

.s13-en {
  font-size: .65rem;
}

.s28 {
  font-size: 1.1rem;
}

.contact__side {
  text-align: center;
}

.list--social {
  margin: 0;
  padding: 0;
}

.list--social__item {
  display: inline-block;
  vertical-align: middle;
}

.html--loaded .reveal--load {
  opacity: 1;
}

/* [class*=reveal--] {
  opacity: 0;
  transition: .25s;
  will-change: opacity;
} */

.department-index {
  color: #272727;
}

.projects__item {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, .15) 0 1.6rem 4.75rem;
  color: #1b1b1b;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 0 auto 1.5rem;
  max-height: 16rem;
  max-width: 16rem;
  height: calc(100vw - 3rem);
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;
  text-align: left;
  transition: -webkit-transform .25s cubic-bezier(.625, .125, .305, .875);
  transition: transform .25s cubic-bezier(.625, .125, .305, .875);
  transition: transform .25s cubic-bezier(.625, .125, .305, .875), -webkit-transform .25s cubic-bezier(.625, .125, .305, .875);
}

.projects__bg {
  background: left center no-repeat;
  background-size: cover;
  border-radius: inherit;
  /* border-bottom-right-radius: inherit; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 66.6%;
}

.projects__item.projects__item--reversed {
  color: #fff;
}

.projects__title {
  font-size: 24px;
  max-width: 18.2rem;
  display: block;
  line-height: 1.3;
  margin: auto 0 0;
  padding: 1.6rem 0;
  position: relative;
  text-align: inherit;
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
}

.projects__logo {
  margin: 0 auto 0 0;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  border: 4px solid #fff;
  padding: 8px 16px;
  border-radius: inherit;
}

[class^=btn--] {
  border: 0;
  border-radius: .2rem;
  display: inline-block;
  font-weight: 700;
  line-height: 1.3;
  margin: .4rem 0;
  min-width: 10.75rem;
  outline: 0;
  font-size: 0.9rem;
  padding: 1.28em 2em 1.08em;
  text-align: center;
  text-decoration: none;
  transition: all .25s cubic-bezier(.625, .125, .305, .875);
  vertical-align: top;
  width: auto;
}

.home-project__airplan_wrapper {
  position: absolute;
  right: 0;
  padding-top: 30%;
  top: 189px;
  -webkit-transform: translate(-10%, -42%);
  transform: translate(1%, 0%);
  width: 30%;
}

.home-project__airplan {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: center no-repeat;
  background-size: cover;
  -webkit-transform: translate3d(0px, 1.77vh, 0px);
          transform: translate3d(0px, 1.77vh, 0px);
}

@media only screen and (max-width: 31.9375rem) {
  .projects__item .projects__bg {
    width: 100%;
  }
  .projects__title {
    max-width: 10em;
  }
  [class^=btn--] {
    font-size: 0.9rem;
    padding: 1.28em 2em 1.08em;
  }
}

@media only screen and (min-width: 32rem) {
  .projects__item {
    height: auto;
    margin-bottom: 3.5rem;
    max-height: none;
    max-width: none;
    padding-right: 36%;
    min-height: 50vw;
  }
  .projects__bg {
    width: 50%;
  }
}

@media only screen and (min-width: 48rem) {
  .projects__item {
    padding-right: 50%;
    min-height: 41vw;
  }
}

@media only screen and (min-width: 81.25rem) {
  .projects__item {
    min-height: 26.65rem;
  }
}

.hover .btn--primary:focus, .hover .btn--primary:hover, .hover .btn--primary:active {
  background: #2c2d33;
  color: #fff;
}

.btn--primary {
  background: #24262b;
  color: #fff;
}

.btn-round {
  border: 0;
  border-radius: 80px;
  display: inline;
  font-weight: 700;
  line-height: 1.3;
  margin: .4rem 0;
  outline: 0;
  font-size: 0.9rem;
  padding: 16px;
  width: 80px;
  text-align: center;
  text-decoration: none;
  transition: all .25s cubic-bezier(.625, .125, .305, .875);
  vertical-align: bottom;
}

.department-text-white-underline {
  display: inline;
  padding-bottom: 4px;
  border-bottom: 4px solid #fff;
}

.department-text-black-underline {
  display: inline;
  padding-bottom: 4px;
  border-bottom: 4px solid #1b1b1b;
}

.department-img-wrapper {
  text-align: left;
}

.department-img-wrapper span {
  font-size: 13pt;
  font-weight: normal;
  color: #666;
}

.department-img-wrapper span:first-of-type {
  margin-top: 16px;
}

.department-img {
  width: 100%;
  margin: 16px 0 0 0;
  border-radius: 5px;
  max-width: 800px;
  display: block
}

.department-q-and-a {
  color: #a02727;
  font-weight: normal;
  font-size: 20px;
}

.department-question {
  color: #a02727;
  font-weight: bold;
}

.department-answer {
  color: #fff;
  font-weight: normal;
}

@keyframes buttonDropDown {
  from {
    opacity: 0;
    -webkit-transform: translate(0%, -100%);
            transform: translate(0%, -100%)
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%)
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes buttonDropDown {
  from {
    opacity: 0;
    -webkit-transform: translate(0%, -100%);
            transform: translate(0%, -100%)
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%)
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    -webkit-transform: translate(0%, -10%);
            transform: translate(0%, -10%)
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%)
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
    -webkit-transform: translate(0%, -10%);
            transform: translate(0%, -10%)
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%)
  }
}

.departments-list-with-little-sa {
  margin: 0;
}

.departments-list-with-little-sa li {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.departments-list-with-little-sa li a {}

.departments-list-with-little-sa li a img {
  width: 100%
}
