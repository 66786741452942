/* Generated by Glyphter (http://www.glyphter.com) on  Thu Mar 14 2019*/
@font-face {
    font-family: 'Glyphter';
    src: url('./fonts/Glyphter.eot');
    src: url('./fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Glyphter.woff') format('woff'),
         url('./fonts/Glyphter.ttf') format('truetype'),
         url('./fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-cssa-logo:before{content:'\0041';}